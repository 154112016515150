// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  baseUrl: 'https://dev.api.dollarflightclub.com',
  url: 'https://dev.app.dollarflightclub.com',
  emailValidators: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
  googleClientId: '656107222648-dmq835bmomr2agh6r6ass0nh5tj599o1.apps.googleusercontent.com',
  "apiContentLoadUrl":"https://dev.api.dollarflightclub.com/dfcmembers/contents",
  "apiSignupUrl":"https://dev.api.dollarflightclub.com/dfc/public/signup",
  "apiAirportsSearchUrl":"https://dev.api.dollarflightclub.com/dfc/public/airports/search",
  "apiSearchDestinations": "https://dev.api.dollarflightclub.com/dfcmembers/search/cities/all",
  "createCheckoutSession": "https://dev.api.dollarflightclub.com/dfc/public/stripe/checkout-session",
  "membersLoginUrl":"https://dev.api.dollarflightclub.com/dfcmembers/login",
  "membersLogoutUrl":"https://dev.api.dollarflightclub.com/dfcmembers/logout",
  "googleLogin":"https://dev.api.dollarflightclub.com/auth/google/login",
  "googleSignup":"https://dev.api.dollarflightclub.com/auth/google/signup",
  "membersSessionUrl":"https://dev.api.dollarflightclub.com/dfcmembers/session",
  "membersRecoverUrl":"https://dev.api.dollarflightclub.com/dfcmembers/recover",
  "resetTokenValidationUrl":"https://dev.api.dollarflightclub.com/dfcmembers/token",
  "passwordResetUrl":"https://dev.api.dollarflightclub.com/dfcmembers/reset",
  "premiumStatsUrl":"https://dev.api.dollarflightclub.com/dfcmembers/premium",
  "memberStatsUrl":"https://dev.api.dollarflightclub.com/dfcmembers/stats",
  "latestDealsUrl":"https://dev.api.dollarflightclub.com/dfcmembers/deals/latest",
  "destinationsSearchUrl":"https://dev.api.dollarflightclub.com/dfcmembers/search/destinations",
  "airportsSearchUrl":"https://dev.api.dollarflightclub.com/dfcmembers/search/airports",
  "lastDealSearchUrl":"https://dev.api.dollarflightclub.com/dfcmembers/deals/last",
  "dealInfoUrl":"https://dev.api.dollarflightclub.com/dfcmembers/deals/get",
  "dealsListUrl":"https://dev.api.dollarflightclub.com/dfcmembers/deals/list",
  "faqsUrl":"https://dev.api.dollarflightclub.com/dfcmembers/faqs",
  "openTicketUrl":"https://dev.api.dollarflightclub.com/dfcmembers/tickets/create",
  "ticketsListUrl":"https://dev.api.dollarflightclub.com/dfcmembers/tickets/list",
  "supportTicketUrl":"https://dev.api.dollarflightclub.com/dfcmembers/tickets/get",
  "replyTicketUrl":"https://dev.api.dollarflightclub.com/dfcmembers/tickets/reply",
  "membershipsListUrl":"https://dev.api.dollarflightclub.com/dfcmembers/memberships/list",
  "membershipSubscriptionUrl":"https://dev.api.dollarflightclub.com/dfcmembers/memberships/subscribe",
  "membershipCouponUrl":"https://dev.api.dollarflightclub.com/dfcmembers/memberships/coupon",
  "membershipUpgradeUrl":"https://dev.api.dollarflightclub.com/dfc/public/upgrade",
  "membershipCancelationUrl":"https://dev.api.dollarflightclub.com/dfcmembers/memberships/cancel",
  "membershipInfoUrl":"https://dev.api.dollarflightclub.com/dfcmembers/memberships/info",
  "membershipPause":"https://dev.api.dollarflightclub.com/dfcmembers/memberships/pause",
  "updateAccountUrl":"https://dev.api.dollarflightclub.com/dfcmembers/account/update",
  "newsLetterSignupUrl":"https://dev.api.dollarflightclub.com/dfcmembers/account/newsLetterSignup",
  "updateCustomerCardInfoUrl": "https://dev.api.dollarflightclub.com/dfcmembers/memberships/card/update",
  "activateNotificationsUrl":"https://dev.api.dollarflightclub.com/dfcmembers/notifications/activate",
  "deactivateNotificationsUrl":"https://dev.api.dollarflightclub.com/dfcmembers/notifications/deactivate",
  "favoriteDestinationsListUrl":"https://dev.api.dollarflightclub.com/dfcmembers/destinations/favorites",
  "favoriteDestinationsAddUrl":"https://dev.api.dollarflightclub.com/dfcmembers/destinations/favorites/add",
  "favoriteDestinationsRemoveUrl":"https://dev.api.dollarflightclub.com/dfcmembers/destinations/favorites/delete",
  "favoriteDeparturesListUrl":"https://dev.api.dollarflightclub.com/dfcmembers/departures/favorites",
  "favoriteDeparturesAddUrl":"https://dev.api.dollarflightclub.com/dfc/public/airports/set",
  "favoriteDeparturesRemoveUrl":"https://dev.api.dollarflightclub.com/dfcmembers/departures/favorites/delete",
  "destinationsListUrl":"https://dev.api.dollarflightclub.com/dfcmembers/search/destinations/all",
  "airlinesSearchUrl":"https://dev.api.dollarflightclub.com/dfcmembers/search/airlines",
  "favoriteAirlinesListUrl":"https://dev.api.dollarflightclub.com/dfcmembers/airlines/favorites",
  "favoriteAirlinesAddUrl":"https://dev.api.dollarflightclub.com/dfcmembers/airlines/favorites/add",
  "favoriteAirlinesRemoveUrl":"https://dev.api.dollarflightclub.com/dfcmembers/airlines/favorites/delete",
  "stripe_key":"pk_test_CyAS4csiRsdfVa9ENFoYs8aQ",
  "app_name":"Dollar Flight Club Members Area",
  "public_area":"https://dollarflightclub.com",
  "promotionInfoUrl":"https://dev.api.dollarflightclub.com/dfcmembers/promotion",
  "updateRegionUrl":"https://dev.api.dollarflightclub.com/dfcmembers/region",
  "hashLoginUrl":"https://dev.api.dollarflightclub.com/dfcmembers/hash",
  "enableDomesticUrl":"https://dev.api.dollarflightclub.com/dfcmembers/enabledomestic",
  "checkCouponUrl":"https://dev.api.dollarflightclub.com/stripe/coupon/find",
  "getPlanPriceUrl":"https://dev.api.dollarflightclub.com/dfc/public/stripe/price",
  "sendReferralUrl": "https://dev.api.dollarflightclub.com/set-referral",
  "publicCheckMembership": "https://dev.api.dollarflightclub.com/dfc/public/user/membership",
  "getInvoicePriceUrl": "https://dev.api.dollarflightclub.com/dfc/public/stripe/invoice",
  "setupIntentUrl": "https://dev.api.dollarflightclub.com/stripe/setup-intent",
  "updateCartIdUrl": "https://dev.api.dollarflightclub.com/dfcmembers/update-cart-id",
  "addConciergeUrl": "https://dev.api.dollarflightclub.com/stripe/add-concierge",
  "createPerkCheckoutSession": "https://dev.api.dollarflightclub.com/dfc/public/perk-checkout-session",
  "addFreeUserToActiveCampaignURL": "https://dev.api.dollarflightclub.com/active-campaign/add-free-user",
  "verifyRecaptchaUrl": "https://dev.api.dollarflightclub.com/auth/verify-recaptcha",
  "getNearestAirportsUrl": "https://dev.api.dollarflightclub.com/dfc/public/airports/nearest",
  "findPopularDestinationsUrl": "https://dev.api.dollarflightclub.com/dfcmembers/destinations/popular",
  "getCurrencyUrl": "https://dev.api.dollarflightclub.com/api/v2/currency/get",
  "getAllPerksUrl": "https://dev.api.dollarflightclub.com/api/v2/perks/all",
  "getPerkUrl": "https://dev.api.dollarflightclub.com/api/v2/perks/get",
  "extendTrialUrl": "https://dev.api.dollarflightclub.com/stripe/extend-trial",
  "cancellationCoupon": "oneyearfreecancel",
  "trialCoupon": "trialdiscount60",
  "getUserPerkProductSubscriptionUrl": "https://dev.api.dollarflightclub.com/api/v2/subscriptions/user"
};
