import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Api} from '../../services/api.service';

@Component({
  selector: 'splash-bg',
  templateUrl: './splash-bg.component.html',
  styleUrls: ['./splash-bg.component.css']
})
export class SplashBgComponent implements OnInit {

  @Input()
  title:string;

  @Input()
  header:string;

  @Input()
  subtitle:string;

  @Input()
  imgUrl:string;

  @Input()
  icon:string;

  @Input()
  custClass:string;

  showBg:boolean = true;
  isPremium: boolean;

  constructor(private route: ActivatedRoute, public api: Api) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.dealType) {
        params.dealType == 1 ? this.showBg = false: this.showBg = true;
      }
    });
    this.api.user$.subscribe(user =>{
      if(user){
        this.isPremium === this.api.premium;
      }
    });
  }
}
