import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Api, MessageType } from '../../../services/api.service';
import * as _ from "lodash";
import { CheckoutComponent } from '../../checkout/checkout.component';

@Component({
  selector: 'app-update-card',
  templateUrl: './update-card.component.html',
  styleUrls: ['./update-card.component.css']
})
export class UpdateCardComponent implements OnInit {
  @ViewChild(CheckoutComponent) checkoutComponent: CheckoutComponent;

  constructor(public ngxModalService: NgxSmartModalService, public api: Api) { }

  ngOnInit() {
  }

  // onSubscribeSuccess(newCard: any){
  //   this.api.updateCustomerCardInfo(newCard, this.api.user).then(() => {
  //     this.close();
  //     this.api.showMessage(MessageType.success, 'Your card information has been successfully updated.');
  //   }).catch((err) => {
  //     this.close();
  //     this.api.showMessage(MessageType.error, 'Unable to update your card information, please try again.');
  //   });
  // }

  ngAfterContentInit() {
    //set checout component child properties. Checkout is router component
    //and inputs cant be easily passed via component [] syntax. todo: improve
    // if(this.checkoutComponent && this.checkoutComponent.subscribeSuccess) {
    //   this.checkoutComponent.modalTitle = `<h3>Update Your Payment Details<h3>`;
    //   this.checkoutComponent.updateBilling = true;
    //
    //   this.checkoutComponent.subscribeSuccess.subscribe(stripeId => {
    //     this.onSubscribeSuccess(stripeId);
    //   });
    // }
  }

  public close(){
    this.ngxModalService.getModal('checkoutModal').close();
  }

  public open(){
    this.ngxModalService.getModal('checkoutModal').open();
  }

}
