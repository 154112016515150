import {animate, group, query, style, transition, trigger, state} from "@angular/animations";

export const filterMenuAnimations = [
  trigger('popOverState', [
    state('show', style({
      opacity: 1,
      display: 'block'
    })),
    state('hide', style({
      opacity: 0,
      display: 'none'
    })),
    transition('show => hide', [
        style({transform: 'translateX(0)'}),
        animate('0.7s ease', style({transform: 'translateX(-100%)', opacity: 0, display: 'none'}))
      ]),
    transition('hide => show', [
        style({transform: 'translateX(-100%)'}),
        animate('0.5s ease', style({transform: 'translateX(0)', opacity: 1, display: 'block'}))
      ])
  ])
  
];